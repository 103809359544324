<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";

/**
* Advanced-table component
*/
export default {
  page: {
    title: "Wallet Statistics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader ,countTo,Multiselect,OrderReciept,DatePicker},
  data() {
    return {
    
      tableData: [],
      walletCardData:[],
      templateArr:[],
      restBranchArr:[],
      orderDetails:[],
      orderedItemArr:[],
      orderItemAddonArr:[],
      orderStatusArr:[],
      restBranchID : "",
      templateID:"",
      countryArr :[],
      country:"",
      transactionTypeArr: [
            {'typeID':1,'name':'Credit'},
            {'typeID':2,'name':'Debit'},
            {'typeID':3,'name':'Expired'},
          ],
      typeID:"",
      dateFilter:[],
      branches:[],
      branchID:"",
      cityArr:[],
      city:"",

      title: "Wallet Statistics",
      items: [
        {
          text: "View",
        },
        {
          text: "Wallet Statistics",
          active: true,
        },
      ],
      corpBranchID:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
    
        {
          key:"employeeName",
          lable: "Customer Name",
          sortable:true,
        },
        {
          key:"employeeID",
          lable: "Customer ID",
          sortable:true,
        },
        {
            key:"corpBranchName",
            sortable: true,
        },
        {
          key: "transactionType",
          sortable: true,
        },
        {
          key: "points",
          sortable: true,
        },
        {
          key: "orderID",
          sortable: true,
        },
       {
          key:"restaurant",
          sortable:true,
        },
        {
          key: "created",
          sortable: true,
        },

      
     
       
      ],
    };
  },
  created(){
    this.corpBranchID = (this.$storageData.profile.empTypeID==5) ? this.brandLogin() : this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

},
 
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
 
    ///this.readWalletStats();

  },
  methods: {
    /**
     * Search the table data with search input
     */
    brandLogin(){
      this.getCorporateCountry();
     
     
    },
     getCorporateCountry(){
        this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {corporateID:this.$storageData.profile.corporateID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getCorporateCities();
                
        });
    },
    getCorporateCities(){
         this.axios
        .post(this.$loggedRole+"/getCorporateCities", {corporateID:this.$storageData.profile.corporateID,
           country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                this.getCorporateBranchByCorpID();
              
                
        });
    },
    getCorporateBranchByCorpID(){
     
         this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {corporateID:this.$storageData.profile.corporateID,
           city: (this.city) ? this.city.city : ""}
        )
        .then((response) => {
        this.branches = response.data.data;
        this.branchID = this.branches[0];
        this.corpBranchID = this.branchID.corpBranchID;
        this.readWalletStats();
         
        });
    },
    onchangeBranch(){
        this.corpBranchID = this.branchID.corpBranchID;
        this.readWalletStats();
    },
    getStats(){
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.readWalletStats();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readWalletStats(){
        this.axios
        .post(this.$loggedRole+"/getWalletStatistics" ,
          {'corpBranchID':this.corpBranchID,
          'transactionType':(this.typeID) ? this.typeID.typeID : 0,
          'dateFilter':this.dateFilter,
          }
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           this.walletCardData = response.data.walletCardData;
           //console.log((response));
         
        });
    },
    applyFilter(){
        this.corpBranchID = (this.$storageData.profile.empTypeID==5) ? this.branchID.corpBranchID : this.$storageData.profile.corpBranchID;
        this.readWalletStats();
  
    },
    clearFilter(){
        this.typeID = 0;
        this.dateFilter = [];
        this.readWalletStats();
    },

viewOrderDetails(orderID){
       // alert(orderID);
        this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
         this.axios.post(this.$loggedRole+"/getOrderDetailsByID",{'orderID':orderID})
          .then((result)=>{
           
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
          //  console.log(result.data.data.orderedItemArr);
            this.orderedItemArr = result.data.data.orderedItemArr;
           // this.orderItemAddonArr = result.data.orderItemAddonArr;
             
        });
    },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-2" style="position: absolute;right: 50%;top: 75px;"
        v-if="this.$storageData.profile.empTypeID==5">

                     <label class="form-label" for="formrow-voucherType-input" style="font-size: 12px;">Select Country </label>
                        <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                          @input="getCorporateCities();" :show-labels="false"></multiselect>
    </div>
    <div class="col-md-2" style="position: absolute;right: 33.5%;top: 75px;" 
            v-if="this.$storageData.profile.empTypeID==5">

                        <label style="font-size: 12px;">Select City </label>
                            <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                              @input="getCorporateBranchByCorpID();" :show-labels="false"></multiselect>
      </div>
    <div class="col-md-2" style="position: absolute;right: 17%;top: 75px;"
         v-if="this.$storageData.profile.empTypeID==5">

                     <label class="form-label"  style="font-size: 12px;">Select Corporate Branch </label>
                        <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                          @input="onchangeBranch();" :show-labels="false"></multiselect>
      </div>
      <div class="row mt-3">
                <div class="col-sm-6 col-md-2" >
                    <label class="form-label" for="formrow-voucherType-input" style="font-size: 12px;">Transaction Type</label>
                        <multiselect v-model="typeID" :options="transactionTypeArr" :show-labels="false" track-by="typeID" label="name"
                            @input="applyFilter();">
                    </multiselect>
                    </div>

          
                     <div class="col-sm-6 col-md-3" >
                        <label class="form-label" style="font-size: 12px;" for="formrow-date-input">Date</label>
                         <date-picker v-model="dateFilter" format="DD MMM Y" value-type="format" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>
                    </div>
                    <div class="col-sm-6 col-md-1 mt-3">
                        <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear </button>
                     </div>
     </div>
<div class="row" style="margin-top: 30px;">
     <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div>
                    <h4 class="mb-1 mt-1">
                       {{walletCardData.walletPointsAdded}}
                        <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                        </span> -->
                    </h4>
                    <p class="text-muted mb-0">Total Points Assigned</p>
                </div>
              
            </div>
        </div>
    </div>

      <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div>
                    <h4 class="mb-1 mt-1">
                        {{walletCardData.walletPointsUsed}}
                        <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                        </span> -->
                    </h4>
                    <p class="text-muted mb-0">Total Points Redeemed</p>
                </div>
              
            </div>
        </div>
    </div>

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div>
                    <h4 class="mb-1 mt-1">
                        {{walletCardData.walletPointsExpired}}
                        <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                        </span> -->
                    </h4>
                    <p class="text-muted mb-0">Total Points Expired</p>
                </div>
              
            </div>
        </div>
    </div>
   

      <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div>
                    <h4 class="mb-1 mt-1">
                       
                        <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="walletCardData.totalAssignedUsers" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Assigned Users</p>
                </div>
              
            </div>
        </div>
    </div>
</div>


    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom:15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button type="button" class="btn btn-themeBrown">Excel</button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
                <!-- <div class="row mb-2">
                    <div class="col-sm-6 col-md-2">
                    <label class="form-label" for="formrow-voucherType-input">Transaction Type</label>
                        <multiselect v-model="typeID" :options="transactionTypeArr" :show-labels="false" track-by="typeID" label="name"
                            @input="applyFilter();">
                    </multiselect>
                    </div>

          
                     <div class="col-sm-6 col-md-3">
                        <label class="form-label" for="formrow-date-input">Date</label>
                         <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>
                    </div>
                    <div class="col-sm-6 col-md-2 mt-3">
                        <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear Filter </button>
                    </div>
            </div> -->
            <!-- Table -->
            <div class="table-responsive mb-0">
             
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
               
               
              >
             


                 <template v-slot:cell(transactionType)="data">
                     <span v-if="data.item.isExpired==1" style="color:#F3766A;"> Expired </span>
                     <span v-else-if="data.item.transactionType==1" style="color:green;"> Credit </span>
                     <span v-else-if="data.item.transactionType==2" style="color:#A4827D;"> Debit </span>
                    
                </template>

                 <template v-slot:cell(points)="data">
                     <span v-if="data.item.points">{{data.item.points}} </span>
                  
                </template>

                <template v-slot:cell(orderID)="data">
                
                  <span v-if="data.item.orderID" style="cursor:pointer;color:#2069f1;" @click="viewOrderDetails(data.item.id);">{{data.item.orderID}}</span>
                  <span v-else > - </span>
                </template>
             
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OrderReciept :orderDetails="this.orderDetails" :orderedItemArr="this.orderedItemArr" :orderStatusArr="this.orderStatusArr">
    </OrderReciept>
  </Layout>
</template>
<style scoped>

.my-class { max-width: 10px !important;}
</style>